<template>
  <div class="box" v-loading.fullscreen.lock="fullscreenLoading">
    <Navigation/>
    <placard_screen path_arr="path_array2" :category_id="2" @screen_confirm="pageChange(1)"
                    @reset_screen="pageChange(1)"/>
    <Title title="资源列表"/>
    <div class="table_1">
      <el-table :data="tableData">
        <el-table-column width="200" current-row-key="currentRowKey" label-class-name="custom-header-class"
                         class-name="custom-column-cell" header-align="center" align="center" label="平台名称">
          <template slot-scope="scope">
            <el_table_column column_type="text" prop="title" :scope="scope"/>
          </template>
        </el-table-column>
        <el-table-column width="100" current-row-key="currentRowKey" label-class-name="custom-header-class"
                         class-name="custom-column-cell" header-align="center" align="center" label="广告位置">
          <template slot-scope="scope">
            <el_table_column column_type="text" prop="address" :scope="scope"/>
          </template>
        </el-table-column>
        <el-table-column width="100" current-row-key="currentRowKey" label-class-name="custom-header-class"
                         class-name="custom-column-cell" header-align="center" align="center" label="平台类型">
          <template slot-scope="scope">
            <el_table_column column_type="text" prop="portal_title" :scope="scope"/>
          </template>
        </el-table-column>
        <el-table-column width="100" current-row-key="currentRowKey" label-class-name="custom-header-class"
                         class-name="custom-column-cell" header-align="center" align="center" label="行业类型">
          <template slot-scope="scope">
            <el_table_column column_type="text" prop="classify_title" :scope="scope"/>
          </template>
        </el-table-column>
        <el-table-column width="170" current-row-key="currentRowKey" label-class-name="custom-header-class"
                         class-name="custom-column-cell" header-align="center" align="center" label="投放价格">
          <template slot-scope="scope">
            <el_table_column column_type="slect_money" :scope="scope" prop="member_guanggao_price"
                             @select_item="select_item"/>
          </template>
        </el-table-column>
        <el-table-column width="200" current-row-key="currentRowKey" label-class-name="custom-header-class"
                         class-name="custom-column-cell" header-align="center" align="center" label="投放周期">
          <template slot-scope="scope">
            <el_table_column column_type="input_number" :scope="scope" @handle_cycle="handle_cycle"/>
          </template>
        </el-table-column>
        <el-table-column width="150" current-row-key="currentRowKey" label-class-name="custom-header-class"
                         class-name="custom-column-cell" header-align="center" align="center" label="设计规格">
          <template slot-scope="scope">
            <el_table_column column_type="text" prop="design_rule" :scope="scope"/>
          </template>
        </el-table-column>
        <el-table-column width="100" current-row-key="currentRowKey" label-class-name="custom-header-class"
                         class-name="custom-column-cell" header-align="center" align="center" label="设计单位">
          <template slot-scope="scope">
            <el_table_column column_type="text" prop="design_unit_text" :scope="scope"/>
          </template>
        </el-table-column>
        <el-table-column width="150" current-row-key="currentRowKey" label-class-name="custom-header-class"
                         class-name="custom-column-cell" header-align="center" align="center" label="设计价格">
          <template slot-scope="scope">
            <el_table_column @handle_price_click="handle_price_click" column_type="button_money"
                             prop="design_price" :scope="scope"/>
          </template>
        </el-table-column>
        <el-table-column width="150" current-row-key="currentRowKey" label-class-name="custom-header-class"
                         class-name="custom-column-cell" header-align="center" align="center" label="备注(仅供参考)">
          <template slot-scope="scope">
            <el_table_column column_type="remark" prop="remark" :scope="scope"/>
          </template>
        </el-table-column>
        <el-table-column header-align="center" label-class-name="custom-header-class"
                         class-name="custom-column-cell" fixed="right" align="center" width="130" label="操作">
          <template slot-scope="scope">
            <el_table_column column_type="controls" :scope="scope" @collect_status="collect_status"/>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <div class="paging_box">
      <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page"/>
    </div>
    <!-- 购物车 -->
    <placard_shop_car :tableDate="tableData" :showCar="showCar" @delete_item="delete_item" @empty="empty"/>

  </div>
</template>

<script>

import {mapState, mapMutations} from 'vuex'
import placard_screen from '@/components/placard_screen'
import el_table_column from '@/components/el_table_column'
import placard_shop_car from '@/components/placard_shop_car'
import {media_screen_} from '@/util/media_screen_'
import {place_select_item} from '@/util/place_select_item'
import {place_select_check} from '@/util/place_select_check'
import {place_delete_item} from '@/util/place_delete_item'

export default {
  components: {
    placard_screen,
    el_table_column,
    placard_shop_car,
  },
  computed: {
    ...mapState(['placard']),
  },

  data() {
    return {
      tableData: [],
      fullscreenLoading: false,
      total_page: 0, //分页总页数
      count: 0, //总条数
      page: 1,
      showCar: false
    }
  },
  methods: {
    select_item(select_data) {
      place_select_item(this.tableData, select_data)
      this.showCar = true
    },
    delete_item(delete_item) {
      place_delete_item(this.tableData, delete_item)
    },
    handle_cycle(currentValue, index) {
      this.tableData[index].cycle = currentValue
    },
    pageChange(pageVal) {
      this.page = pageVal
      this.get_list()
    },
    handle_price_click(index) {
      place_select_check(this.tableData, index)
      this.showCar = true
    },
    collect_status(status, index) {
      this.tableData[index].is_collect = status
    },
    empty() {
      this.tableData.forEach((item, index) => {
        this.tableData[index].check = false
        this.tableData[index].type = 0
        this.tableData[index].cycle = 1
      })
    },
    // 获取列表数据
    get_list() {
      this.fullscreenLoading = true
      let data = media_screen_(this.placard.date_form)
      data.page = this.page
      data.limit = 20
      this.curlGet('/api/guanggao_info/list', data).then(res => {
        if (res.data.code) {
          this.tableData = res.data.data.list
          this.total_page = res.data.data.total_page
          this.count = res.data.data.count
          this.tableData.forEach((item, index) => {
            this.$set(this.tableData[index], 'check', false)
            this.$set(this.tableData[index], 'type', 0)
            this.$set(this.tableData[index], 'cycle', 1)
            if (this.placard.car_list.length != 0) {
              this.placard.car_list.forEach((el, i) => {
                if (this.tableData[index].id == this.placard.car_list[i].id) {
                  this.tableData[index] = this.placard.car_list[i]
                }
              })
            }
          })
        }
        this.fullscreenLoading = false
      })
    },
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/Media';
@import '@/scss/placard_media';
</style>